.Header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #262626;
    padding: 10px;
}
.header-logo,
.header-premium {
    display: flex;
    flex-direction: row;
    align-items: center;
}
.header-premium {
    background: #4C8EDF;
    gap: 10px;
    border-radius: 6px;
    padding: 10px 15px;
    color: white;
    font-size: 18px;
    font-weight: 500;
    cursor: pointer;
}
.header-logo {
    color: #262626;
    font-weight: 600;
    font-size: 36px;
    font-family: "Poppins";
}