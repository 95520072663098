.chat-container {
    display: flex;
    flex-direction: row;
    height: 100%;
    width: 80%;
}

.videoAvatar {
    width: 200px;
    height: 200px;
    border-radius: 50%;
    overflow: hidden;
    position: relative;
    margin: 10px;
}
video {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.videoAvatar::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    opacity: 0;
    transition: opacity 0.3s ease;
}
.videoAvatar:hover::after {
    opacity: 1;
}

.Chat {
    display: flex;
    flex-direction: column-reverse;
    width: 80%;
    height: 100%;
}
input {
    width: 80%;
    height: 30px;
}
.chat-wrapper {
    margin: 25px;
}
.message {
    margin: 5px;
    padding: 12px 16px;
    border-radius: 12px;
    width: 80%;
}
.user {
    background-color: #b7d2f2;
    color: #15171E;
    border-top-left-radius: 0px
}
  
.bot {
    position: relative;
    right: -20%;
    background-color: #eef4fc;
    color: #15171E;
    border-top-right-radius: 0px;
}
.thumb {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;
}
.bot-thumb {
    position: relative;
    right: -100%;
}
.user-thumb {
    position: relative;
    right: 20px;
}
.bot-thumb span {
    text-transform: capitalize;
}
.bot-thumb .circle-container {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    overflow: hidden;
}
.bot-thumb .circle-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
}