.WelcomeScreen {
    text-align: center;
}
h1 {
    font-weight: bold;
    font-size: 48px;
    color: #000;
    line-height: 48px;
    margin: 0px;
}
p {
    font-size: 24px;
    color: #000;
    line-height: 48px;
    margin: 0px;
}
.speakersList {
    display: flex;
    flex-direction: row;
}
.talkButton {
    background: #262626;
    color: #fff;
    width: 50%;
    padding: 10px;
    margin: 0 auto;
    margin-top: 50px;
    cursor: pointer;
}