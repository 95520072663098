.icons {
    position: relative;
    margin: 10px;
    border: 1px solid gray;
    padding: 20px 10px;
    border-radius: 10px;
}
.icons .circle-container {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    overflow: hidden;
}
.icons .circle-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.icons.selected {
    border: 2px solid #36DA8B;
}
.icons .speakerName {
    margin-top: 10px;
    text-transform: capitalize;
}
.vSign {
    position: absolute;
    top: -14px;
    left: 71px;
}

.thumbs {
    gap: 10px;
    color: white;
    display: flex;
    flex-direction: row;
    padding: 3px;
    align-items: center;
}
.thumbs .circle-container {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    overflow: hidden;
}
.thumbs .circle-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.thumbs.selected {
    background: #3C3C3C;
}
.thumbs .speakerName {
    margin-top: 10px;
    text-transform: capitalize;
}
.thumbs .vSign {
    display: none;
}