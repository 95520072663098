.Login {
    display: flex;
    flex-direction: column;
    align-items: center;
}
input {
    height: 20px;
    border: 1px solid #E8E8E8;
    border-radius: 5px;
    background-color: #fefefe;
    padding: 3px 5px;
    margin: 5px;
}
button {
    margin-top: 30px;
    padding: 5px 20px;
    font-size: 16px;
    cursor: pointer;
    border: 1px solid #4C8EDF;
    background-color: #fff;
    border-radius: 10px;
}
button:hover {
    background-color: #4C8EDF;
    color: white;
}
small {
    margin-top: 10px;
    color: red;
}